import { graphql } from 'gatsby';
import { StructuredTextDocument } from 'react-datocms';
import React from 'react';
import CustomStructuredTextRenderer from '../../custom-structured-text-renderer';
import Container from '../../../primitives/grid/container';
import Row from '../../../primitives/grid/row';
import Col from '../../../primitives/grid/col';
import Badge from '../../../primitives/badge';
import Typography from '../../../primitives/typography';
import RowSet from '../../../primitives/grid/row-set';
import MediaItem from '../../../primitives/media-item';
import { borderRadius, breakpoints } from '../../../primitives/tokens';
import StyledLink from '../../../primitives/styled-link';
import { getLink } from '../../../primitives/link';
import AspectRatio from '../../../primitives/aspect-ratio';
import Mobile from '../../../primitives/devices/mobile';
import TabletHorizontal from '../../../primitives/devices/tablet-horizontal';
import TabletVertical from '../../../primitives/devices/tablet-vertical';

export type FeatureSetProps = Queries.FeatureSetPropsFragment;

export const GenericDevice = ({ mediaItem, device, children = null }): JSX.Element => {
    const aspectRatioItem = mediaItem?.[0]?.media?.[0]?.image || mediaItem?.[0]?.media?.[0]?.video;
    if (device === 'tablet_horizontal') {
        return (
            <div css={{
                maxWidth: '600px',
                width: '100%',
                margin: 'auto',
                position: 'relative',
                [breakpoints.mb]: {
                    maxWidth: 'max(70vw, 300px)',
                },
            }}
            >
                <AspectRatio
                    breakpoints={{
                        dt: { width: aspectRatioItem?.width || 2123, height: aspectRatioItem?.height || 1628 },
                    }}
                    css={{
                        boxShadow: '0 24px 54px rgba(0, 0, 0, 0.08)',
                        borderRadius: borderRadius.large,
                        overflow: 'hidden',
                        transform: 'translateZ(0)',
                    }}
                >
                    <MediaItem
                        css={{
                            width: '100%',
                            height: '100%',
                        }}
                        item={mediaItem}
                        muted
                        autoPlay
                    />
                </AspectRatio>
                {children}
            </div>
        );
    }

    if (device === 'tablet_vertical') {
        return (
            <div css={{
                maxWidth: '392px',
                width: '100%',
                margin: 'auto',
                position: 'relative',
                [breakpoints.mb]: {
                    maxWidth: 'max(40vw, 240px)',
                },
            }}
            >
                <AspectRatio
                    breakpoints={{
                        dt: { width: aspectRatioItem?.width || 1628, height: aspectRatioItem?.height || 2123 },
                    }}
                    css={{
                        boxShadow: '0 24px 54px rgba(0, 0, 0, 0.08)',
                        borderRadius: borderRadius.large,
                        overflow: 'hidden',
                        transform: 'translateZ(0)',
                    }}
                >
                    <MediaItem
                        css={{
                            width: '100%',
                            height: '100%',
                        }}
                        item={mediaItem}
                        muted
                        autoPlay
                    />
                </AspectRatio>
                {children}
            </div>
        );
    }

    if (device === 'mobile') {
        return (
            <div css={{
                maxWidth: '240px',
                width: '100%',
                margin: 'auto',
                position: 'relative',
                [breakpoints.mb]: {
                    maxWidth: 'max(32vw, 150px)',
                },
            }}
            >
                <AspectRatio
                    breakpoints={{
                        dt: { width: aspectRatioItem?.width || 884, height: aspectRatioItem?.height || 1776 },
                    }}
                    css={{
                        boxShadow: '0 24px 54px rgba(0, 0, 0, 0.08)',
                        borderRadius: borderRadius.large,
                        overflow: 'hidden',
                        transform: 'translateZ(0)',
                    }}
                >
                    <MediaItem
                        css={{
                            width: '100%',
                            height: '100%',
                        }}
                        item={mediaItem}
                        muted
                        autoPlay
                    />
                </AspectRatio>
                {children}
            </div>
        );
    }

    return (
        <AspectRatio
            breakpoints={{
                dt: { width: aspectRatioItem?.width || 609, height: aspectRatioItem?.height || 411 },
            }}
            css={{
                boxShadow: '0 24px 54px rgba(0, 0, 0, 0.08)',
                borderRadius: borderRadius.large,
                overflow: 'hidden',
                transform: 'translateZ(0)',
            }}
        >
            <MediaItem
                css={{
                    width: '100%',
                    height: '100%',
                }}
                item={mediaItem}
            />
        </AspectRatio>
    );
};

export const FramedDevice = ({ mediaItem, device, children = null }): JSX.Element => {
    const aspectRatioItem = mediaItem?.[0]?.media?.[0]?.image || mediaItem?.[0]?.media?.[0]?.video;
    if (device === 'tablet_horizontal') {
        return (
            <div css={{
                maxWidth: '600px',
                width: '100%',
                margin: 'auto',
                position: 'relative',
                [breakpoints.mb]: {
                    maxWidth: 'max(70vw, 300px)',
                },
            }}
            >
                <TabletHorizontal>
                    <MediaItem
                        css={{
                            width: '100%',
                            height: '100%',
                        }}
                        item={mediaItem}
                        muted
                        autoPlay
                    />
                </TabletHorizontal>
                {children}
            </div>
        );
    }

    if (device === 'tablet_vertical') {
        return (
            <div css={{
                maxWidth: '392px',
                width: '100%',
                margin: 'auto',
                position: 'relative',
                [breakpoints.mb]: {
                    maxWidth: 'max(40vw, 192px)',
                },
            }}
            >
                <TabletVertical>
                    <MediaItem
                        css={{
                            width: '100%',
                            height: '100%',
                        }}
                        item={mediaItem}
                        muted
                        autoPlay
                    />
                </TabletVertical>
                {children}
            </div>
        );
    }

    if (device === 'mobile') {
        return (
            <div css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                position: 'relative',
            }}
            >
                <div css={{
                    position: 'relative',
                    maxWidth: '240px',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    padding: '80px 0',
                    width: '100%',
                    [breakpoints.mb]: {
                        padding: '40px 0',
                        maxWidth: 'max(40vw, 192px)',
                        width: '100%',
                        margin: 'auto',
                    },
                }}
                >
                    <Mobile largeFormat>
                        <MediaItem
                            css={{
                                height: '100%',
                                width: '100%',
                            }}
                            item={mediaItem}
                            objectFit="cover"
                            autoPlay
                            muted
                        />
                    </Mobile>
                    {children}
                </div>
            </div>
        );
    }

    return (
        <AspectRatio
            breakpoints={{
                dt: { width: aspectRatioItem?.width || 609, height: aspectRatioItem?.height || 411 },
            }}
            css={{
                boxShadow: '0 24px 54px rgba(0, 0, 0, 0.08)',
                borderRadius: borderRadius.large,
                overflow: 'hidden',
                transform: 'translateZ(0)',
            }}
        >
            <MediaItem
                css={{
                    width: '100%',
                    height: '100%',
                }}
                item={mediaItem}
            />
        </AspectRatio>
    );
};

const FeatureSet = ({
    richTitle,
    cards,
}: FeatureSetProps): JSX.Element => (
    <Container css={{
        marginTop: '110px',
        marginBottom: '160px',
        [breakpoints.mb]: {
            marginTop: '78px',
            marginBottom: '64px',
        },
    }}
    >
        <Typography
            fontSize={{
                dt: 4053,
                tb: 3234,
                mb: 2835,
            }}
            override
            css={{
                textAlign: 'center',
                marginBottom: '84px',
                [breakpoints.mb]: {
                    marginBottom: '58px',
                },
            }}
            fontWeight="light"
        >
            <CustomStructuredTextRenderer data={richTitle as unknown as StructuredTextDocument} />
        </Typography>
        <Row css={{
            gridAutoFlow: 'column',
            rowGap: '145px',
            [breakpoints.tb]: {
                rowGap: '100px',
            },
            [breakpoints.mb]: {
                rowGap: '74px',
            },
        }}
        >
            {cards && cards.map((card) => {
                if (!card) return null;

                const {
                    orientation, showDeviceFrames, device, link, badge, title, mediaItem, richText,
                } = card;

                return (
                    <>
                        <Col breakpoints={{
                            dt: {
                                span: 6,
                                start: orientation === 'image-on-right' ? 6 : 0,
                            },
                            mb: {
                                span: 4,
                                start: 0,
                                hidden: true,
                            },
                        }}
                        >
                            {showDeviceFrames && (<FramedDevice device={device} mediaItem={card?.mediaItem} />)}
                            {!showDeviceFrames && (<GenericDevice device={device} mediaItem={mediaItem} />)}
                        </Col>
                        <Col breakpoints={{
                            dt: {
                                span: 6,
                                start: orientation === 'image-on-right' ? 0 : 6,
                            },
                            mb: {
                                span: 4,
                                start: 0,
                            },
                        }}
                        >
                            <RowSet
                                breakpoints={{
                                    dt: { between: 22 },
                                    mb: { between: 30 },
                                }}
                                css={{
                                    maxWidth: '538px',
                                    width: '88%',
                                    marginLeft: orientation === 'image-on-right' ? '0' : 'auto',
                                    marginRight: orientation === 'image-on-left' ? '0' : 'auto',
                                    [breakpoints.tb]: {
                                        width: '100%',
                                        margin: 'auto',
                                    },
                                }}
                            >
                                {badge && (
                                    <div css={{
                                        textAlign: 'left',
                                        [breakpoints.mb]: {
                                            textAlign: 'center',
                                        },
                                    }}
                                    >
                                        <Badge invert>
                                            {badge}
                                        </Badge>
                                    </div>
                                )}
                                <Typography
                                    fontSize={{
                                        dt: 4053,
                                        tb: 3234,
                                        mb: 2027,
                                    }}
                                    override
                                    css={{
                                        textAlign: 'left',
                                        [breakpoints.mb]: {
                                            textAlign: 'center',
                                        },
                                    }}
                                    fontWeight="light"
                                >
                                    {title}
                                </Typography>
                                <div css={{
                                    display: 'none',
                                    width: '100%',
                                    [breakpoints.mb]: {
                                        display: 'block',
                                    },
                                }}
                                >
                                    {showDeviceFrames && (<FramedDevice device={device} mediaItem={card?.mediaItem} />)}
                                    {!showDeviceFrames && (<GenericDevice device={device} mediaItem={mediaItem} />)}
                                </div>
                                <Typography
                                    fontSize={{
                                        dt: 1822,
                                        mb: 1420,
                                    }}
                                    override
                                    css={{
                                        opacity: 0.75,
                                        textAlign: 'left',
                                        p: {
                                            marginBottom: '12px',
                                        },
                                        [breakpoints.mb]: {
                                            textAlign: 'center',
                                        },
                                    }}
                                >
                                    <CustomStructuredTextRenderer
                                        data={richText as unknown as StructuredTextDocument}
                                    />
                                    {(link && link.length > 0) && (
                                        <div css={{
                                            paddingTop: '24px',
                                            margin: 'auto',
                                            textAlign: 'left',
                                            [breakpoints.mb]: {
                                                textAlign: 'center',
                                                paddingTop: '32px',
                                            },
                                        }}
                                        >
                                            <StyledLink
                                                to={getLink(link[0].link)}
                                            >
                                                {link[0].title}
                                            </StyledLink>
                                        </div>
                                    )}
                                </Typography>
                            </RowSet>
                        </Col>
                    </>
                );
            })}
        </Row>
    </Container>
);

export default FeatureSet;

export const query = graphql`
    fragment FeatureSetProps on DatoCmsFeatureSet  {
        richTitle {
            blocks
            links
            value
        }
        cards {
            device
            showDeviceFrames
            badge
            mediaItem {
                ...MediaItemProps
            }
            orientation
            richText {
                blocks
                links
                value
            }
            title
            link {
                ...LinkWithTitleProps
            }
        }
    }
`;
